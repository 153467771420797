@import '~ol/ol.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.Mui-disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sr-only {
  display: none;
}

.userName {
  font-size: 1em !important;
  line-height: 1.5em !important;
}

.userRole {
  font-size: 0.5em !important;
  text-transform: uppercase;
  line-height: 1.1em !important;
}

/* .MuiTabs-flexContainer {
  float: right;
} */

.MuiFormHelperText-root.Mui-error {
  margin-bottom: -16px;
}

#popup-container::after,
#popup-forecast::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
}

#popup-container::before,
#popup-forecast::before {
  content: '';
  position: absolute;
  bottom: -21px;
  left: calc(50% - 21px);
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  border-top: 21px solid grey;
}

#report-container::after {
  content: '';
  position: absolute;
  bottom: -19px;
  left: calc(50% - 20px);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
}

#report-container::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: calc(50% - 21px);
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  border-top: 21px solid #80808094;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.image-popup-class {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 320px;
  background-color: #ffffffcc;
}

.image-popup-class img {
  display: block;
  border-radius: 4px;
  margin: 0 auto;
  max-width: 100%;
}

.image-popup-class h4 {
  font-size: 1rem;
  text-align: center;
  margin: 0;
}

.image-popup-class p {
  font-size: 1.1rem;
  margin-top: 10px;
  text-align: center;
}

#marker-popup-div::after {
  content: '';
  position: absolute;
  bottom: -19px;
  left: calc(50% - 20px);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffffffcc;
}

#marker-popup-div::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: calc(50% - 21px);
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  border-top: 21px solid transparent;
}

#marker-popup-div {
  border-bottom: 1px solid transparent;
}

/* .marker-popup-class img {
  display: block;
  border-radius: 4px;
  margin: 0 auto;
  max-width: 100%;
}

.marker-popup-class h4 {
  font-size: 1rem;
  text-align: center;
  margin: 0;
}

.marker-popup-class p {
  font-size: 1.1rem;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
}

.marker-popup-solid {
  border-top: 1px solid #bbb;
} */
.fc-toolbar { text-transform: capitalize; }
.fc .fc-col-header-cell-cushion { text-transform: capitalize; }
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) { text-transform: capitalize; }
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) { text-transform: capitalize; }
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) { text-transform: capitalize; }